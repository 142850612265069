import { useEffect } from "react";
import Tracking from "../../API/MICROSERVICES/TRACKING/tracking";

export default function Pruebas() {



    async function Prueba(){

        console.log("Prueba");

        let orden = {
            of: 986974649,
          }

        Tracking.detalle(orden).then((resp:any)=>{
            console.log(resp);
        });



    }


    useEffect(() => {
          
      return () => {
         Prueba();
      }
      
    }, [])
    


    return (
        <>

            <h4> Hola, soy un componente de prueba </h4>
        </>


    )
}
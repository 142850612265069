import Swal from "sweetalert2";

export default function AlertaCentral(mensaje: string, reload?: string, footer?: string, text?: string, tk?: string) {

    let Title: any = "";
    let Html = "";
    let Text: any = "";
    let Icon: any = "";
    let ConfirmeButtonText: any = "";
    let Footer: any = "";
    let AllowOutsideClick:any = true;
    let ConfirmButtonColor = "";
    let Ticket:any = "";

    if (mensaje == String("ERRORCARGATICKET")) {

      Title = 'Error!';
      Html = 'Problema al recuperar <b>Tickets</b>';
      Text = "Búsqueda Erronea";
      Icon = "error";
      ConfirmeButtonText = "Continuar";
      AllowOutsideClick = false;
      Footer = "";
      ConfirmButtonColor = "red";



      // Title = "No ha Ingresado Orden de Flete";
      // Text = "Debe Ingresar una Orden de Flete";
      // Icon = "error";
      // ConfirmeButtonText = "Continuar";
      // Footer = "";

    } else if (mensaje == String("NOBULTOCIEGO")) {

      Title = 'Alerta!';
      Html = 'Sucursal Seleccionada no registra Bultos Ciegos';
      Text = "Búsqueda Sin Resultados";
      Icon = "error";
      ConfirmeButtonText = "Continuar";
      AllowOutsideClick = false;
      Footer = "";
      ConfirmButtonColor = "red";


      // Title = "Error";
      // Text = "Problema con la Orden de Flete";
      // Icon = "error";
      // ConfirmeButtonText = "Continuar";
      // Footer = footer;

    } else if (mensaje == String("OFCORRECTA")) {

      Title = 'Correcto!';
      Html = 'Orden de Flete Permite Continuar';
      Text = "Búsqueda Correcta";
      Icon = "success";
      ConfirmeButtonText = "Continuar";
      AllowOutsideClick = false;
      Footer = "";
      ConfirmButtonColor = "green";

    } else if (mensaje == String("NOBULTOCIEGOCOLOR")) {

      Title = 'Alerta!';
      Html = 'El color Seleccionado no registra Bultos Ciegos';
      Text = "Búsqueda Sin Resultados";
      Icon = "error";
      ConfirmeButtonText = "Continuar";
      AllowOutsideClick = false;
      Footer = "";
      ConfirmButtonColor = "red";

    } else if (mensaje == String("DIASMAXIMOS")) {

      Title = "Error";
      Text = "El rango máximo de días es de 15, límite superado. ";
      Icon = "error";
      ConfirmeButtonText = "Cerrar";
      Footer = "";

    } else if (mensaje == String("NOTICKETRANGOFECHA")) {

      Title = 'Alerta!';
      Html = 'Rango de Fechas Seleccionada no registra Bultos Ciegos';
      Text = "Búsqueda Sin Resultados";
      Icon = "error";
      ConfirmeButtonText = "Continuar";
      AllowOutsideClick = false;
      Footer = "";
      ConfirmButtonColor = "red";



    } else if (mensaje == String("NOBULTOCIEGOMULTI")) {

      Title = 'Alerta!';
      Html = 'La Busqueda avanzada Seleccionada no registra Bultos Ciegos';
      Text = "Búsqueda Sin Resultados";
      Icon = "error";
      ConfirmeButtonText = "Continuar";
      AllowOutsideClick = false;
      Footer = "";
      ConfirmButtonColor = "red";

    }
    else if (mensaje == String("RANGOFECHASUPERIOR")) {

      Title = 'Alerta!';
      Html = 'Las Fechas Seleccionadas Son mas Antiguas que 7 meses.';
      Text = "Búsqueda Sin Resultados";
      Icon = "error";
      ConfirmeButtonText = "Continuar";
      AllowOutsideClick = false;
      Footer = "";
      ConfirmButtonColor = "red";

    }
    else if (mensaje == String("OFNOEXISTE")) {

      Title = 'Error!';
      Html = 'La Orden de Flete Ingresada no Existe </br>' + text;     
      Icon = "error";
      ConfirmeButtonText = "Continuar";
      AllowOutsideClick = false;
      Footer = "";
      ConfirmButtonColor = "red";

    }
    else if (mensaje == String("OFLARGA")) {

      Title = 'Error!';
      Html = 'La Orden de Flete Ingresada tiene muchos Digitos  </br>' + text;     
      Icon = "error";
      ConfirmeButtonText = "Continuar";
      AllowOutsideClick = false;
      Footer = "";
      ConfirmButtonColor = "red";

    }
    else if (mensaje == String("OFCORTA")) {

      Title = 'Error!';
      Html = 'La Orden de Flete Ingresada No tienes suficiente Digitos </br>' + text;     
      Icon = "error";
      ConfirmeButtonText = "Continuar";
      AllowOutsideClick = false;
      Footer = "";
      ConfirmButtonColor = "red";

    }

    else if (mensaje == String("OFCONESTADOINALTERABLE")) {

  
      Title = 'Error!';
      Html = 'La Orden de Flete Ingresada <b>'+ text +'</b> <br> \n posee <b>Estado INALTERABLE</b>' ;     
      Icon = "error";
      ConfirmeButtonText = "Continuar";
      AllowOutsideClick = false;
      Footer = "";
      ConfirmButtonColor = "red";

    }

    else if (mensaje == String("DEBEINGRESAROF")) {

      Title = 'Error!';
      Html = 'DEBE INGRESAR UNA ORDEN DE FLETE';     
      Icon = "error";
      ConfirmeButtonText = "Continuar";
      AllowOutsideClick = false;
      Footer = "";
      ConfirmButtonColor = "red";

    }
    else if (mensaje == String("FALLAAPI")) {

      Title = 'Error!';
      Html = 'Falla en la comunicación con los servicios';     
      Icon = "error";
      ConfirmeButtonText = "Continuar";
      AllowOutsideClick = false;
      Footer = footer;
      ConfirmButtonColor = "red";

    }
    else {

      Title = "Error Indeterminado";
      Text = "Algo a fallado";
      Icon = "success";
      ConfirmeButtonText = "Continuar";
      Footer = "";

    }








    if (reload !== "recargar") {

      Swal.fire({
        title: Title,
        html: Html,
        text: Text,
        icon: Icon,
        confirmButtonText: ConfirmeButtonText,
        allowOutsideClick: AllowOutsideClick,
        footer: Footer,
        confirmButtonColor: ConfirmButtonColor,
        
      });

    } else {

      Swal.fire({
        title: Title,
        html: Html,
        text: Text,
        icon: Icon,
        confirmButtonText: ConfirmeButtonText,
        allowOutsideClick: AllowOutsideClick,
        footer: Footer,
        confirmButtonColor: ConfirmButtonColor,
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.reload();
        }
      });

    }


  }


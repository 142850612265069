
import Pruebas from "../VIEWS/PRUEBA/prueba";

import App from "../App";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ValidadorOf from "../VIEWS/01_VALIDADOR_OF/01_Validador_OF";
import ValidadorOfBc from "../VIEWS/02_VALIDADOR_OF_BC/01_Validador_OF_BC";
import DominioIncorrecto from "../VIEWS/DominioIncorrecto";
import View404 from "../VIEWS/404";

export default function RoutesMain() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />} />

        <Route path="/Prueba" element={<Pruebas />} />

        <Route path="/ValidadorOf" element={<ValidadorOf />} />

        <Route path="/ValidadorOfBc" element={<ValidadorOfBc />} />

        {/* DOMINIO INCORRECTO */}
        <Route path="/InValidDomain" element={<DominioIncorrecto/>} />

        {/*  ERROR */}
        <Route path="*" element={<View404/>}  />

      </Routes>
    </BrowserRouter>
  );
}